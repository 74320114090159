import type { TGeneral } from '~/src/types/common'
import { useAuthStore } from '@/stores/auth'
import type { THubSpotUser } from '~/src/types/hubspot'

export const useHubSpotUser = () => {
  const { $restClient }: TGeneral = useNuxtApp()
  const authStore = useAuthStore()
  const { hubSpotUser, hasQueryHubSpot } = storeToRefs(authStore)

  const fetchUserInfo = async () => {
    if (hasQueryHubSpot.value) {
      return hubSpotUser.value
    }

    try {
      const response = await $restClient.getUserInfoHubspot()
      authStore.setHubSpotUser(response)
    } catch (e) {
      logError(e)
      authStore.setHubSpotUser(null)
    } finally {
      hasQueryHubSpot.value = true
    }
  }

  const setUserInfo = async (user: THubSpotUser) => {
    try {
      await $restClient.setUserInfoHubspot(user)
      authStore.setHubSpotUser({ ...hubSpotUser.value, ...user })
    } catch (e) {
      logError(e)
      authStore.setHubSpotUser(null)
    }
  }

  return {
    hubSpotUser,
    fetchUserInfo,
    setUserInfo,
  }
}
